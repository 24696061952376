//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AnimatedLink from '@/components/common/AnimatedLink.vue'

export default {
  components: {
    AnimatedLink
  }
}
