//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const lg = '(min-width: 1024px)'
const md = '(max-width: 1023.99px) and (min-width: 501px)'
const sm = '(max-width: 500.99px)'

export default {
  props: {
    bgColor: {
      type: String,
      default: '#57bc60'
    },
    title: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    link: {
      type: String,
      default: '/'
    },
    linkLabel: {
      type: String,
      default: null
    },
    img: {
      type: String,
      default: null
    },
    imgAlt: {
      type: String,
      default: null
    },
    double: {
      type: Boolean,
      default: false
    },
    arrowVariant: {
      type: String,
      default: ''
    },
    arrowVariantHover: {
      type: String,
      default: ''
    },
    hoverLinkBgColor: {
      type: String,
      default: '#0a2341'
    },
    hoverLinkTextColor: {
      type: String,
      default: '#ffffff'
    },
    textColor: {
      type: String,
      default: '#ffffff'
    },
    imgCentered: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      detailsStyle: '',
      linkStyle: '',
      linkHoverStyle: '',
      isHovered: false,
      interval: null,
      windowWidth: typeof window !== 'undefined' ? window.innerWidth : null,
      isPC: typeof window !== 'undefined' ? window.matchMedia(lg).matches : false,
      isTablet: typeof window !== 'undefined' ? window.matchMedia(md).matches : false,
      isMobile: typeof window !== 'undefined' ? window.matchMedia(sm).matches : false
    }
  },
  computed: {
    getArrow () {
      if ((typeof this.arrowVariant === 'string' && this.arrowVariant !== '') && (!this.isHovered || (this.isTablet && this.isMobile))) {
        return this.arrowVariant
      }
      if ((typeof this.arrowVariantHover === 'string' && this.arrowVariantHover !== '') && (this.isHovered && (!this.isTablet && !this.isMobile))) {
        return this.arrowVariantHover
      }
      return require('@/assets/img/home/arrow-white.png')
    },
    getLinkStyle () {
      if (typeof this.linkStyle === 'string' && typeof this.linkHoverStyle === 'string') {
        return this.linkStyle.concat((!this.isTablet && !this.isMobile) ? this.linkHoverStyle : '')
      }
      return ''
    }
  },
  mounted () {
    this.detailsStyle = `color: ${this.textColor};`
    this.linkStyle = `color: ${this.textColor};`
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.onResize()
  },
  beforeDestroy () {
    clearTimeout(this.interval)
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize () {
      clearTimeout(this.interval)
      this.interval = setTimeout(() => {
        this.windowWidth = window.innerWidth
        this.isPC = window.matchMedia(lg).matches
        this.isTablet = window.matchMedia(md).matches
        this.isMobile = window.matchMedia(md).matches || window.matchMedia(sm).matches
      }, 500)
    },
    linkHover (status) {
      // console.log(status)
      if (status) {
        this.linkHoverStyle = `background-color: ${this.hoverLinkBgColor}; color: ${this.hoverLinkTextColor}`
        return
      }
      this.linkHoverStyle = ''
    }
  }
}
